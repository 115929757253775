@import "../partials/variables";

.lang-rtl {
  * {
    direction: rtl !important;
    text-align: right;
  }
  .btn, button {
    text-align: center;
  }
  label, .label {
    text-align: left;
    @include media-breakpoint-down(md) {
      text-align: right;
    }
  }
  .text-right {
    text-align: left !important;
  }
  .text-left {
    text-align: right !important;
  }
  .float-right {
    float: left !important;
  }

/* general */
  .st-menu {
    left: auto;
    right: 0;
    .st-menu-close {
      right: auto;
      left: 0;
    }
  }
  .st-effect-left.st-menu {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  .st-menu-right {
    right: auto;
    left: 0;
    .st-menu-close {
      left: auto;
      right: 0;
    }
  }
  .st-effect-right.st-menu-right {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  .dropdown .dropdown-icon {
    margin-left: 0;
    margin-right: 6px;
  }
  .caret-right {
    &:before {
      border-width: 0.3em 0.3em 0.3em 0;
    }
  }
  .linklist li a:before {
    content: '\F104';
    padding-right: 0;
    padding-left: 8px;
  }
  .view-all-link {
    padding-left: 0;
    padding-right: 15px;
    .material-icons {
      transform: rotate(180deg);
    }
  }
  .typo ul {
    padding-right: 15px;
    padding-left: 0;
    &.linklist {
      padding-right: 0;
    }
  }
  .done {
    margin-right: 0;
    margin-left: 2%;
  }
  .flex-scrollbox-wrapper {
    direction: ltr !important;
    > div:first-child, > ul:first-child {
      direction: ltr !important;
    }
  }
  @include media-breakpoint-up(lg) {
    .slick-prev {
      right: auto;
      left: 10px;
    }
    .slick-next {
      right: auto;
      left: 41px;
    }
  }
  .aone-brands {
    .slick-prev {
      left: -5px;
    }
    .slick-next {
      right: -5px;
    }
  }
  .custom-select {
    padding-left: 24px;
    padding-right: 10px;
    background-position: left 0.75rem center;
  }
  .input-group >.form-control:not(:last-child) {
    border-radius: 0 5px 5px 0;
  }
  .input-group-btn:not(:first-child) > .btn {
    border-radius: 5px 0 0 5px;
  }
  .form-control-select {
    padding-right: 0.75rem;
    padding-left: 24px;
    background-position: left 1px center;
  }
  .toggle-linklist-title:after {
    right: auto;
    left: 0;
  }
  .modal-footer {
    text-align: left;
  }
  .modal-header .close {
    right: auto;
    left: 0;
    border-radius: 0.3rem 0;
  }
  .alert-warning .material-icons {
    margin-right: 0;
    margin-left: 20px;
  }
  .alert ul {
    padding-right: 20px;
    padding-left: 0;
  }

/* header */
  .currency-selector-wrapper + .language-selector-wrapper, .language-selector-wrapper + .currency-selector-wrapper {
    margin-right: 5px;
    margin-left: 0;
  }
  #header {
    .display-top {
      > div {
        margin-left: 0;
        margin-right: 2%;
      }
    }
    .left-nav-trigger {
      right: 0;
      left: auto;
    }
    .sticky-icon-cart {
      left: 0;
      right: auto;
    }
    .desktop-header-version .search-widget {
      @include media-breakpoint-only(md) {
        margin-right: auto;
        margin-left: 0;
      }
    }
  }
  .mobile-header-version .customer-signin-module {
    right: auto;
    left: 0;
  }
  #header .checkout-header-version .checkout-header-right .customer-signin-module {
    margin-left: 0;
    margin-right: 2%;
  }
  .search-widget form {
    input[type="text"] {
      padding-left: 53px;
      padding-right: 5%;
    }
    [type="submit"] {
      right: auto;
      left: 0;
      border-radius: 5px 0 0 5px;
    }
  }
  .ui-autocomplete.ui-menu .search-menu-item .search-item .cover {
    margin-right: 0;
    margin-left: 2%;
  }
  .anav-top {
    .amenu-link > img {
      margin-left: 5px;
      margin-right: -5px;
    }
    .acategory-content {
      .category-title img {
        margin-left: 5px;
        margin-right: -5px;
        position: relative;
        z-index: 1;
      }
      .category-subs li a:before {
        margin-right: 0;
        margin-left: 6px;
      }
      .category-subs li a img {
        margin-right: -15px;
        margin-left: 5px;
        position: relative;
        z-index: 1;
      }
    }
  } 
  #amegamenu .anav-top .amenu-link > sup {
    right: auto;
    left: 5px;
  }
  #mobile-amegamenu .anav-top .mobile-toggle-plus {
    margin-right: auto;
    margin-left: -10px;
  }
  .user-info {
    .dropdown-customer-account-links {
      right: auto;
      left: 0;
    }
    .dropdown-customer-account-links .dropdown-menu {
      border-radius: 0 3px 3px 3px;
    }
  }
  .cart-preview .cart-dropdown {
    right: auto;
    left: 0;
  }
  .category-tree {
    > ul > li > a {
      img {
        margin-left: 0;
        margin-right: -8px;
        position: relative;
        z-index: 1;
      }
    }
    .collapse-icons {
      right: auto;
      left: -5px;
    }
    .category-sub-menu {
      padding-left: 0;
      padding-right: 28px;
      > ul > li {
        .collapse-icons {
          right: auto;
          left: 0;
        }
        &:before, &:after {
          left: auto;
          right: -14px;
          border-left-width: 0;
          border-right-width: 1px;
        }
        &:last-child:before {
          border-radius: 0 0 5px 0;
        }
      }
      .category-sub-menu {
        padding-left: 0;
        padding-right: 14px;
      }
    }
  }

/* home */
  .aone-slideshow {
    .slide-products-related {
      right: auto;
      left: 5%;
    }
    .nivoSlider .nivo-controlNav {
      left: auto;
      right: 10%;
    }
  }
  .add-to-cart.added:after {
    right: auto;
    left: -5px;
  }
  .product-list {
    .thumbnail-left .product-miniature {
      .second-block {
        padding-left: 0;
        padding-right: 5%;
      }
    }
    .table-view .product-miniature {
      .second-block {
        padding-left: 2%;
        padding-right: 3%;
      }
    }
    .product-mobile-slider {
      margin-right: -5px;
      margin-left: 0;
    }
    .list .product-miniature .product-availability {
      margin-left: 0;
      margin-right: 20px;
    }
  }
  .grade-stars .star-content {
    left: auto;
    right: 1px;
  }
  .star-content div.star-on i {
    margin-left: 0;
    margin-right: -1px;
  }
  .aone-featuredcategories {
    .title-block .title-icon {
      margin-right: 0;
      margin-left: 8px;
    }
    .categories-mobile-slider {
      margin-right: -10px;
      margin-left: 0;
    }
  }
  .brand-mobile-slider {
    margin-right: -5px;
    margin-left: 0;
  }
  .aone-popupnewsletter {
    button.close {
      right: auto;
      left: -8px;
    }
    .popup-content {
      margin-right: auto;
    }
  }

/* footer */
  .footer-right .block-newsletter {
    .form-control {
      border-radius: 0 5px 5px 0;
      border-right-width: 1px;
      border-left-width: 0;
    }
    .input-btn {
      @include media-breakpoint-down(sm) {
        min-width: fit-content;
      }
    }
    .input-btn .btn {
      border-radius: 5px 0 0 5px;
    }
  }
  #scrollTopButton {
    right: auto;
    left: 3%;
  }

/* category page */
  .breadcrumb .breadcrumb-item .separator {
    transform: rotate(180deg);
  }
  #search_filters .facet {
    .facet-title .fa-angle-double-right {
      transform: scaleX(-1);
      text-align: left;
    }
    .facet-label .custom-checkbox input[type="checkbox"]:checked + .check-shape.color {
      margin-left: 0;
      margin-right: -3px;
    }
  }
  .active-filters .filter-block .material-icons {
    margin-left: -3px;
    margin-right: 0;
  }
  .products-selection .products-sort-order {
    .sort-label {
      margin-right: 0;
      margin-left: 10px;
    }
    .product-display {
      margin-right: auto;
      margin-left: 0;
      .display-label {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }
  .pagination .page-list {
    .previous {
      padding-right: 0;
      padding-left: 10px;
      .material-icons {
        transform: rotate(180deg);
      }
    }
    .next {
      padding-left: 0;
      padding-right: 10px;
      .material-icons {
        transform: rotate(180deg);
      }
    }
  }

/* product page */
  .main-product-details {
    .product-cover {
      .layer {
        right: auto;
        left: 0;
        padding-right: 0;
        padding-left: 5px;
      }
    }
    .product-pack .pack-product-quantity {
      border-right-width: 1px;
      border-left-width: 0;
    }
    .product-customization .custom-file button {
      right: auto;
      left: -1px;
      border-radius: 5px 0 0 5px;
    }
  }
  .zone-product-extra-fields {
    .extra-field-type-modal .extra-title {
      background: linear-gradient(to left, #ececec, #ffffff);
      span {
        padding-left: 0;
        padding-right: 5px;
      }
    }
  }
  .mfp-close {
    right: auto;
    left: 0;
  }
  .mfp-preloader .uil-spin-css, .page-loading-overlay .uil-spin-css {
    direction: ltr !important;
  }
  .social-sharing li a i {
    padding-right: 0;
    padding-left: 8px;
  }
  #product-comments-list-pagination ul li span.next i, #product-comments-list-pagination ul li span.prev i {
    transform: rotate(180deg);
  }

/* cart & checkout page */
  .cart-continue-shopping .btn {
    .material-icons {
      transform: rotate(180deg);
    }
  }
  #checkout-addresses-step .address-item .address-footer a + a {
    margin-left: 0;
    margin-right: 10%;
  }

/* other page */ 
  .manufacturer-list .brand .brand-products .url-view .material-icons {
    transform: rotate(180deg);
  }
  .fa-sign-out, .fa-sign-in {
    transform: rotate(180deg);
    text-align: left;
  }
  &.page-addresses .address .address-footer a + a {
    margin-left: 0;
    margin-right: 10%;
  }
  .my-mail-alerts {
    .mail-alert-line {
      .p-name {
        margin-left: 0;
        margin-right: 10px;
      }
      .p-remove {
        margin-left: 0;
        margin-right: auto;
      }
    }
  }
  .sitemap .category-sitemap .tree > li > ul.nested > li > ul.nested {
    border-left-width: 0;
    border-right-width: 1px;
    > li > a:before {
      left: auto;
      right: -15px;
    }
  }
}
