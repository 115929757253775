@import "../partials/variables";

// grid products
.elementor-product-grid .product-miniature {
  height: 100%;
  padding: 0 10px;
}
.elementor-widget-product-carousel {
  .slick-track {
    display: flex;
    align-items: stretch;
    padding-bottom: 10px;
  }
  .slick-slide-inner,
  .product-miniature,
  .product-container {
    height: 100%;
  }
  .slick-slide {
    height: initial;
    > div {
      height: 100%;
      > .slick-slide {
        height: 100%;
      }
    }
  }
  .slick-slide-inner {
    padding: 0 10px;
  }
}
.elementor-widget-product-grid {
  .product-container {
    height: 100%;
  }
}
.grid .product-container {
  min-height: 100%;
}
.elementor-product-grid,
.elementor-widget-product-carousel,
.grid {
  //product container
  .product-container {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .second-third-block {
    display: flex;
    position: relative;
    padding: 30px 15px 20px;
    @include media-breakpoint-down(xl) {
      flex-direction: column;
    }
  }
  .sld-product-manufacturer {
    display: inline-block;
    color: #b1b1b1;
    font-size: rem(18);
    font-weight: 500;
    padding-bottom: 6px;
  }
  .second-block {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(xxl) {
    flex: 1;
    min-height: 72px;
    }
  }
  .third-block {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-left: 20px;
    @include media-breakpoint-up(xxl) {
      padding-top: 40px;
    }
  }
  // prices
  .product-price-and-shipping {
    flex-direction: column;
    align-items: flex-end;
  }
  .product-price {
    display: flex;
    font-size: rem(18);
  }
  .regular-price {
    font-size: rem(18);
  }
  .text-for-list {
    display: none;
  }
  .buttons-sections {
    position: absolute;
    z-index: 10;
    top: -40px;
    right: 20px;
  }
  .add-to-cart {
    background-color: #fff;
    color: $color_secondary;
    box-shadow: $shadow;
    border-radius: 50%;
    border-color: #fff;
    font-size: 28px;
    padding: 16px;
  }
}
// END - grid products

// product list manufacturer image or name
.product-list {
  .product-manufacturer {
    margin: 0 !important;
    padding-left: 0 !important;
    padding: 10px 10px 4px;
    img {
      display: block;
      max-width: 120px;
      max-height: 40px;
    }
  }
  .bestkit_icons_title {
    display: none;
  }
}
// END - product list manufacturer
.product-list {
  .slick-next,
  .slick-prev {
    &:before {
      display: inline-block;
      content: '\e901' !important;
      font-size: 12px !important;
      line-height: 30px !important;
      font-family: $font_icon !important;
      color: #000 !important;
    }
    width: 30px !important;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #b8b8b8;
    box-shadow: $shadow;
    opacity: 1;
    &.slick-disabled {
      opacity: .5 !important;
    }
  }
  .slick-prev {
    &:before {
      transform: rotate(180deg);
    }
  }
}
// END - product list manufacturer

#category {
  .sidebar-column  {
    @include media-breakpoint-up(lg) {
      margin-top: 104px;
    }
    @include media-breakpoint-only(md) {
      margin-top: 138px;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 20px;
    }
  }
}