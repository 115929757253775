@import "../partials/variables";

.page-loading-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  display: none;
  .page-loading-backdrop {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
  }
  .uil-spin-css {
    transform: scale(0.5);
  }
  &.main-product-details-loading, &.cart-overview-loading {
    position: absolute;
    z-index: 100;
    .uil-spin-css {
      transform: scale(0.25);
    }
  }
  &.add-to-cart-loading, &.quickview-loading {
    position: absolute;
    z-index: auto;
    .uil-spin-css {
      transform: scale(0.125);
    }
  }
}

.js-hidden {
  display: none !important;
}
.js-overflow-hidden {
  overflow: hidden;
}

/*** WRAPPER ***/
#wrapper {
  background: $wrapper-background;
  padding-bottom: 20px;
  @if not $center-enable-background {
    #index &, #cms & {
      overflow-x: hidden;
    }
  }
  #checkout & {
    min-height: calc(100vh - 160px);
    padding-top: 25px;
  }
}
.main-content {
  .mobile-main-content {
    display: flex;
    flex-direction: column-reverse;
    .sidebar-column {
      margin-top: 25px;
    }
    #left-column, #center-column, #right-column {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  #product & {
    &> .row  {
      margin: 0;
    }
    #content-wrapper {
      padding: 0;
    }
  }
}

.breadcrumb-wrapper {
  background: $breadcrumb-background;
  overflow: hidden;
  #index & {
    display: none;
  }
}
.breadcrumb {
  margin: 0 -5px;
  padding: 10px 0;
  background: transparent;
  .breadcrumb-item {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    padding: 0;
    .item-name {
      padding: 5px;
    }
    .separator {
      font-size: 1rem;
    }
    &:before {
      display: none;
    }
  }
  &:not([data-depth="1"]) {
    .breadcrumb-item:first-child {
      span {
        display: none;
      }
    }
  }
}
#category.category-depth-level-1 .breadcrumb .breadcrumb-item:first-child span {
  display: initial;
}
.mobile-breadcrumb-wrapper {
  background: transparent;
  margin-bottom: 10px;
  .breadcrumb {
    padding: 5px 0;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    touch-action: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .breadcrumb-item {
      font-size: 0.75rem;
      white-space: nowrap;
      > a {
        padding: 5px 2px;
      }
    }
  }
}

/*** MAIN ***/
#notifications {
  .alert {
    margin-bottom: 25px;
    #index & {
      margin-bottom: 50px;
    }
  }
  ul {
    list-style: square;
    padding-left: 20px;
  }
  a {
    border-bottom: 1px solid;
  }
}

#main {
  .page-header {
    margin-bottom: 25px;
  }
  .page-content {
    margin-bottom: 25px;
    #notifications {
      padding-top: 0;
      margin-left: ($grid-gutter-width / -2);
      margin-right: ($grid-gutter-width / -2);
    }
  }
  .page-footer {
    margin-bottom: 15px;
  }
}

/*** FOOTER ***/
#footer {
  color: $footer-text-color;
  .footer-main {
    background-color: $footer-background;
    padding: 35px 0 30px;
    h2, h3, .h4 {
      text-transform: uppercase;
      font-size: rem(18);
      margin-bottom: 12px;
      color: #929292;
    }
    ul {
      margin-bottom: 15px;
      flex: 1;
      min-width: 200px;
    }
    @include media-breakpoint-up(xl) {
      .main-main {
        margin-left: -30px;
        margin-right: -30px;
      }
      .footer-left, 
      .footer-right {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    @include media-breakpoint-up(xl) {
      padding: 70px 0 40px;
    }
  }
  .footer-bottom {
    background: $footer-background;
    font-size: 90%;
    padding: 20px 0;
  }
  .footer-about-us {
    .fa, .material-icons {
      width: 20px;
      color: $link-color;
    }
  }
  .checkout-footer-content {
    padding: 12px 0;
  }
}

#scrollTopButton {
  display: none;
  position: fixed;
  right: 3%;
  bottom: 80px;
  text-align: center;
  opacity: 0.5;
  transition: all 0.3s;
  z-index: 15;
  .scroll-button {
    display: block;
    width: 40px;
    height: 40px;
    border: 2px solid;
    line-height: 36px;
    font-size: 25px;
    color: $light-text-color;
    background-color: $icon-color;
    .fa, .material-icons {
      position: absolute;
      left: 0;
      right: 0;
      top: 10px;
      text-align: center;
      transition: all 0.3s;
    }
  }
  &:hover {
    opacity: 1;
    .fa, .material-icons {
      top: 8px;
    }
  }
}

.toggle-linklist-title {
  position: relative;
  cursor: pointer;
  margin-top: 8px;
  &:after {
    content: "";
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: transparent;
    border-top-color: inherit;
    border-bottom-color: inherit;
    position: absolute;
    right: 0;
    top: 8px;
  }
}

.toggle-block-title {
  position: relative;
  cursor: pointer;
  transition-delay: 0s;
  &:after {
    content: "";
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: transparent;
    border-top-color: inherit;
    border-bottom-color: inherit;
    position: absolute;
    right: 15px;
    top: 20px;
  }
  &.is-hide {
    margin-bottom: -15px;
    border-bottom-color: transparent;
    transition-delay: 0.4s;
  }
}


/*** TYPOGRAPHY ***/
.banner {
  a img {
    transition: all 0.3s;
    &:hover {
      filter: brightness(75%);
    }
  }
}
.banner1 {
  max-width: 380px;
  margin: 0 auto 15px;
  > a {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  img {
    width: 100%;
    margin-bottom: 10px;
    transition: all 0.3s;
    &:hover {
      filter: brightness(75%);
    }
  }
  span:not(.btn) {
    font-size: 0.875rem;
    font-weight: bold;
    color: $title-color;
    text-transform: uppercase;
    display: block;
  }
  span.btn {
    white-space: nowrap;
    display: block;
    padding-left: 10px;
    padding-right: 10px;
  }
  @include media-breakpoint-between(md, lg) {
    > a {
      flex-direction: column;
    }
    span:not(.btn) {
      margin-bottom: 5px;
    }
  }
}
.banner2 {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  a {
    position: relative;
    display: block;
    &:before {
      content: "";
      position: absolute;
      border: 1px solid $link-hover-color;
      right: 0;
      bottom: 0;
      left: 0;
      top: 0;
      visibility: hidden;
      transition: all 0.3s;
    }
    &:hover:before {
      visibility: visible;
      left: 10px;
      top: 10px;
      right: 10px;
      bottom: 10px;
    }
  }
}
.banner3 {
  margin-bottom: 20px;
  position: relative;
  &:hover {
    background-color: $link-color;
    img {
      opacity: 0.8;
    }
  }
  a.btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translatey(-50%);
    min-width: 100px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s;
  }
  &:hover a.btn {
    visibility: visible;
    opacity: 1;
  }
}
.banner4 {
  position: relative;
  z-index: 1;
  margin-bottom: 15px;
  .background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-attachment: fixed;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
    .layout-full-width &,
    .outside-container & {
      left: -100%;
      right: -100%;
      @if $center-enable-background {
        left: -$center-padding;
        right: -$center-padding;
      }
    }
  }
  .outer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .content {
    width: 100%;
    text-align: center;
  }
  h1, h2, h3 {
    font-size: 30px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  p {
    margin: 0 auto 20px;
  }
}

.feature, .feature2 {
  margin-bottom: 15px;
  padding: 20px 5%;
  border: 1px solid $border-color;
  border-radius: 5px;
  background: $box-background;
  display: flex;
  flex-direction: column;
  align-items: center;
  .material-icons, .fa {
    font-size: 35px;
  }
  a {
    font-size: 1.125rem;
    margin-top: 15px;
    color: inherit;
    line-height: 1.4em;
    text-align: center;
  }
}
.feature {
  .material-icons, .fa {
    color: $link-color;
  }
  a:hover {
    color: $link-hover-color;
  }
}
.feature2 {
  .material-icons, .fa {
    color: $link-hover-color;
  }
  a:hover {
    color: $link-color;
  }
}

.typo {
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: initial;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  ul {
    margin-bottom: 1rem;
    list-style: initial;
    padding-left: 15px;
  }
  ol {
    padding-left: 15px;
  }
  ul, ol {
    li +li {
      padding-top: 5px;
    }
  }
  li > ul {
    margin-top: 5px;
  }
  ul.linklist {
    list-style: none;
    padding-left: 0;
  }
  blockquote, .blockquote {
    padding: .25rem 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    border-left: 4px solid $border-color;
  }
  pre code {
    white-space: pre-wrap;
  }
  iframe {
    max-width: 100%;
  }
}

.js-dropdown-toggle {
  .dropdown-title {
    display: block;
    position: relative;
    cursor: pointer;
    &:after {
      content: "\E145";
      font-family: 'Material Icons';
      font-weight: 700;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .dropdown-content {
    display: none;
  }  
}


/*** BOXED LAYOUT ***/
.boxed-layout {
  margin: auto;
  margin-top: 25px;
  margin-bottom: 50px;
  max-width: $container-xs-max-width;
  padding: 0 5px;
  @include media-breakpoint-up(sm) {
    max-width: map-get($container-max-widths, sm) + 10px;
  }
  @include media-breakpoint-up(md) {
    max-width: map-get($container-max-widths, md)+ 10px;
  }
  @include media-breakpoint-up(lg) {
    max-width: map-get($container-max-widths, lg)+ 10px;
  }
  @include media-breakpoint-up(xl) {
    max-width: map-get($container-max-widths, xl)+ 10px;
  }

  #index & #wrapper {
    overflow-x: initial;
  }
  .banner4 {
    .background {
      left: ($grid-gutter-width / -2);
      right: ($grid-gutter-width / -2);
    }
  }
}

.google-map-iframe {
  iframe {
    width: 100%;
  }
}

.cookie-message {
  position:fixed;
  bottom:0;
  left:0;
  right:0;
  z-index: 99;
  display: none;
  .cookie-message-wrapper {
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .cookie-message-content {
    font-size: 1rem;
    padding: 15px;
    p {
      margin-bottom: 0;
    }
    a {
      opacity: 0.8;
      color: inherit;
      text-decoration: underline;
      transition: all .2s ease-in-out;
      &:hover {
        opacity: 1;
        text-decoration: none;
      }
    }
    @include media-breakpoint-down(md) {
      font-size:  0.875rem;
      padding: 10px;
    }
  }
  .cookie-close-button {
    display: block;
    padding: 15px 3%;
    font-size: 0.9rem;
    font-weight: 700;
    border-radius: 0;
    background: #14a7d0;
    color: #ffffff;
    &:hover {
      background: #3acdf6;
    }
    @include media-breakpoint-down(md) {
      font-size:  0.875rem;
      padding: 10px;
    }
  }
}

/*** MOBILE TOUCH SCREEN ***/
body.touch-screen {
  .banner4 .background {
    background-attachment: initial;
    background-size: initial;
    background-position: center center;
  }
  .banner2 a:before {
    display: none;
  }
  .banner3 a.btn {
    visibility: visible;
    opacity: 1;
  }
  .banner3:hover img {
    opacity: 1;
  }
  .main-product-details .product-cover .zoomWrapper {
    pointer-events: none;
  }
  .zoomContainer {
    display: none;
  }
  .pace {
    .pace-progress {
      background: transparent;
    }
    .pace-progress-inner {
      box-shadow: none;
    }
  }
}

/* loading.io */
.uil-spin-css {
  display: block;
  background: none;
  width: 200px;
  height: 200px;
  min-width: 200px;
  min-height: 200px;
}
@-webkit-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-ms-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes uil-spin-css {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
  100% {
    opacity: 0.1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.uil-spin-css > span {
  display: block;
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: 4px;
  position: absolute;
}
.uil-spin-css > span > span {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: #000;
}
.uil-spin-css > span:nth-of-type(1) > span {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}
.uil-spin-css > span:nth-of-type(1) {
  -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
}
.uil-spin-css > span:nth-of-type(2) > span {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.12s;
  -moz-animation-delay: 0.12s;
  -webkit-animation-delay: 0.12s;
  -o-animation-delay: 0.12s;
  animation-delay: 0.12s;
}
.uil-spin-css > span:nth-of-type(2) {
  -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
}
.uil-spin-css > span:nth-of-type(3) > span {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  -webkit-animation-delay: 0.25s;
  -o-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.uil-spin-css > span:nth-of-type(3) {
  -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
}
.uil-spin-css > span:nth-of-type(4) > span {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.37s;
  -moz-animation-delay: 0.37s;
  -webkit-animation-delay: 0.37s;
  -o-animation-delay: 0.37s;
  animation-delay: 0.37s;
}
.uil-spin-css > span:nth-of-type(4) {
  -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
}
.uil-spin-css > span:nth-of-type(5) > span {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -webkit-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.uil-spin-css > span:nth-of-type(5) {
  -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
}
.uil-spin-css > span:nth-of-type(6) > span {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.62s;
  -moz-animation-delay: 0.62s;
  -webkit-animation-delay: 0.62s;
  -o-animation-delay: 0.62s;
  animation-delay: 0.62s;
}
.uil-spin-css > span:nth-of-type(6) {
  -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
}
.uil-spin-css > span:nth-of-type(7) > span {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  animation-delay: 0.75s;
}
.uil-spin-css > span:nth-of-type(7) {
  -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
}
.uil-spin-css > span:nth-of-type(8) > span {
  -ms-animation: uil-spin-css 1s linear infinite;
  -moz-animation: uil-spin-css 1s linear infinite;
  -webkit-animation: uil-spin-css 1s linear infinite;
  -o-animation: uil-spin-css 1s linear infinite;
  animation: uil-spin-css 1s linear infinite;
  -ms-animation-delay: 0.87s;
  -moz-animation-delay: 0.87s;
  -webkit-animation-delay: 0.87s;
  -o-animation-delay: 0.87s;
  animation-delay: 0.87s;
}
.uil-spin-css > span:nth-of-type(8) {
  -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
}