@import "../partials/variables";

/// Generate line after and before the container, use for the title
///
/// @author Nicolas Lohé
///
/// @access public
///
/// @param {Color} [default value] - color line
/// @param {Length} $height [default value] - line height
/// @param {Length} $width [default value] - line width
///
/// @example scss - Utilisation
///     .title {
///         @include afterBeforeLine();
///     }   
///
@mixin afterBeforeLine($color: #000000, $height: 4px, $width: 100%) {
    position: relative;
    &:before,
    &:after {
        content: '';
        display: block;
        width: $width;
        height: $height;
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        background-color: $color;
    }
    &:before {
        right: 100%;
    }
    &:after {
        left: 100%;
    }
}

/// Generate line under the content 
///
/// @author Nicolas Lohé
///
/// @access public
///
/// @param {Color} $color [default value] - color line
/// @param {String} $start [default value] - position start animation line
/// @param {Number} $bottom [default value] - postion line under the content
/// @param {Number} $height [default value] - line height
/// @param {Number} $width [default value] - line width
///
/// @example scss - Utilisation
///     .title {
///         @include underlineHover(#ff0000, 'middle', -10px);
///     }   
///
@mixin underlineHover($color: #000000, $start: 'start', $bottom: -2px, $height: 2px, $width: 100%) {
    position: relative;
    &:after {
        content: '';
        width: 0;
        opacity: 0;
        height: $height;
        background-color: $color;
        transition: .3s width, .3s opacity;
        position: absolute;
        bottom: $bottom;
        @if $start == 'start' {
            left: 0;
        }
        @else if $start == 'middle' {
            left: 50%;
        }
        @else if $start == 'end' {
            right: 0;
        }
    }
    &:hover {
        &:after {
            width: $width;
            opacity: 1;
            @if $start == 'middle' {
                left: 0;
            }
        }
    }
}


@mixin arrow($color: #000000, $height: 10px, $width: 2px) {
    display: inline-block;
    width: $height * 1.5;
    height: $height * 1.5;
    position: relative;
    &:before,
    &:after {
        content: '';
        display: block;
        width: $width;
        height: $height;
        background-color: $color;
        position: absolute;
        transition: 0.3s;
    }
    &:before {
        top: calc(#{$height} / 1.86);
        transform: rotate(45deg);
    }
    &:after {
        bottom: calc(#{$height} / 1.86);
        transform: rotate(-45deg);
    }
}

/// Generate arrow to a link
///
/// @author Nicolas Lohé
///
/// @access public
///
/// @param {Color} [default value] - color arrow
/// @param {Length} $height [default value] - height arrow
/// @param {Length} $width [default value] - width arrow
/// @param {String} $direction [default value] - direction arrow
/// @param {Number} $horizontal [default value] - 
///
/// @example scss - Utilisation
///     .btn {
///         @include arrow-link($color_primary, 20px, 20px);
///     }   
///
@mixin arrow-link($color, $height ,$width, $direction: 'left', $horizontal: 0) {
    @include arrow($color, $height, $width);

    @if $direction == 'left' {
        &:before,
        &:after {
            left: $horizontal;
        }
    }
    @else if $direction == 'right' {
        &:before,
        &:after {
            right: $horizontal;
        }
    }
}

/// Generate animate sail on the container
///
/// @author Nicolas Lohé
///
/// @access public
///
/// @param {Color} [default value] - color line
/// @param {Length} $height [default value] - line height
/// @param {Length} $width [default value] - line width
///
/// @example scss - Utilisation
///     .sld-home-mea {
///         @include animateSail($color_primary, 0.7, 100%);
///     }   
///
@mixin animateSail($color: #ffffff, $opacity:0.5, $width: 150%, $height: 200%, $rotation: 45deg) {
    &:after {
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;
        content: '';
        display: block;
        width: $width;
        height: $height;
        background-color: rgba($color, $opacity);
        transform: rotate(45deg) translateY(- $width);
        transition: $transition * 1.2 transform;
    }

    &:hover,
    &:focus {
        &:after {
            transform: rotate($rotation) translateY(-($width / 2));
        }
    }
}

/// Generate animate overlay for hover and focus
///
/// @author Nicolas Lohé
///
/// @access public
///
/// @param {Color} $color [default value] - color line
/// @param {Length} $height [default value] - line height
/// @param {Length} $width [default value] - line width
///
/// @example scss - Utilisation
///     .sld-home-mea {
///         @include animateSail($color_primary, 0.7, 100%);
///     }   
///
@mixin animateOverlay($color: #000, $opacity:0.5, $width: 100%, $height: 100%) {
    &:after {
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;
        content: '';
        display: block;
        width: $width;
        height: $height;
        background-color: rgba($color, $opacity);
        transition: $transition visibility, $transition background-color;
    }

    &:hover,
    &:focus {
        &:after {
            background-color: rgba($color, 0);
            visibility: hidden;
        }
    }
}


/// Generate animate text for hover and focus
///
/// @author Nicolas Lohé
///
/// @access public
///
/// @param {String} $selector - selector class or element title
/// @param {Color} $color [default value] - color text
/// @param {Length} $direction [default value] - direciton text
/// @param {Length} $transition [default value] - transition text
///
/// @example scss - Utilisation
///     .sld-home-mea {
///         @include animateSail(h2, #fff, top);
///     }   
///
@mixin animateText($selector, $color: #fff, $direction: 'top', $transition: $transition) {
    #{$selector} {
        position: absolute;
        z-index: 20;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $color;
        opacity: 1;
        transition: $transition opacity, $transition transform;
    }
    &:hover,
    &:focus {
        #{$selector} {
            @if $direction == 'top' {
                transform: translate(-50%, -100%);
            }
            @else if $direction == 'right' {
                transform: translate(0, -50%);
            }
            @else if $direction == 'left' {
                transform: translate(-100%, -50%);
            }
            @else if $direction == 'bottom' {
                transform: translate(-50%, 0);
            }
            opacity: 0;
        }
    }
}

/// Generate triangle
///
/// @author Nicolas Lohé
///
/// @access public
///
/// @param {Length} $width [default value] - triangle width
/// @param {String} $direction [default value] - direction text
/// @param {Color} $color [default value] - color text
///
/// @example scss - Utilisation
///     .flag:before {
///         @include triangle(5px);
///     }
///
@mixin triangle($width, $direction: 'top', $color: #000) {
    width: 0;
    height: 0;
    @if $direction == 'top' {
        border-left: $width solid transparent;
        border-right: $width solid transparent;
        border-bottom: $width solid $color;
    }
    @else if $direction == 'bottom' {
        border-left: $width solid transparent;
        border-right: $width solid transparent;
        border-top: $width solid $color;
    }
    @else if $direction == 'left' {
        border-top: $width solid transparent;
        border-bottom: $width solid transparent;
        border-right: $width solid $color;
    }
    @else if $direction == 'right' {
        border-top: $width solid transparent;
        border-bottom: $width solid transparent;
        border-left: $width solid $color;
    }
}