.st-pusher {
  position: relative;
  left: 0;
  -webkit-transition: -webkit-transform 0.5s;
  transition: all 0.5s;
}

.st-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: none;
  overflow-y: scroll;
}

.st-effect-right .st-overlay {
  display: block;
  z-index: 1000;
}

.st-menu {
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 300px;
  height: 100%;
  overflow: auto;
  background: $light-background;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.st-effect-top {
  z-index: 1002;
}
.st-effect-left {
  z-index: 1000;
}
.st-effect-right {
  z-index: 1001;
}

.st-menu::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.2);
  content: '';
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.st-menu-right {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1001;
  visibility: hidden;
  width: 100%;
  max-width: 400px;
  height: 100%;
  overflow: auto;
  background: $light-background;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.st-menu-right::after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.2);
  content: '';
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.st-menu-open {
  overflow: hidden;
}

.st-menu-open .st-menu::after {
  width: 0;
  height: 0;
  opacity: 0;
  -webkit-transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
  transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
}

.st-menu-title {
  padding: 15px 5px;
  text-align: center;
  font-size: 1.125rem;
  margin: 0;
}

/* Individual effects */
.st-effect-top {
  &.st-menu {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    &:after {
      display: none;
    }
  }
  &.st-menu-open {
    .st-effect-top.st-menu {
      visibility: visible;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
}

.st-effect-left {
 &.st-menu {
   -webkit-transform: translate3d(0, -100%, 0);
   transform: translate3d(0, -100%, 0);
   &:after {
     display: none;
   }
 }
  &.st-menu-open {
    .st-effect-left.st-menu {
      visibility: visible;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
}

.st-effect-right {
  &.st-menu-right {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  &.st-menu-open {
    .st-effect-right.st-menu-right {
      visibility: visible;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      &:after {
        display: none;
      }
    }
  }
}

/* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
.no-csstransforms3d .st-pusher,
.no-js .st-pusher {
  padding-left: 300px;
}

// st menu top
.st-menu.st-effect-top {
  width: 100%;
  background-color: rgba(0,0,0,.8);
  .st-sidebar-top-main {
    position: relative;
  }

  .sld-js-search-close {
    position: absolute;
    z-index: 10;
    top: 20px;
    right: 20px;
    border: none;
    background-color: transparent;
    font-size: rem(24);
    color: #fff;
  }
}
// END - st menu top

// st menu left
.st-effect-left {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  .st-menu-header {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    min-height: 120px;
  }
  .st-menu-main {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
    overflow-x: hidden;
    flex: 1;
  }
  .st-menu-login {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding-left: 10px;
    padding-right: 10px;
    .sld-icon-user {
      font-size: rem(26);
      color: #C01311;
      margin-right: 10px;
    }
    .st-menu-login-text {
      display: flex;
      align-items: center;
      color: #C01311;
      padding: 3px 4px;
      &:last-child {
        &:before {
          content: '';
          float: left;
          display: block;
          width: 1px;
          height: 10px;
          background-color: #C01311;
          margin-right: 6px;
        }
      }
    }
  }
  .st-menu-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    min-height: 10px;
    color: #fff;
    margin-top: auto;
    padding-left: 10px;
    padding-right: 10px;
    .btn-unstyle {
      background-color: transparent;
    }
    .language-selector-wrapper,
    .currency-selector-wrapper {
      display: flex;
      align-items: center;
      height: 60px;
    }
  }
}
// END - st menu left

// st menu right
.st-menu-right {
  display: flex;
  flex-direction: column;
  .st-menu-close {
    padding: 10px 4px;
    font-size: rem(14);
    width: 100%;
    background-color: $color_gray;
    cursor: pointer;
  }
  .sidebar-cart {
    flex: 1;
  }
}

// END - st menu right