@import "../partials/variables";

@font-face {
    font-family: '#{$icomoon-font-family}';
    src:
            url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?hhmapk') format('truetype'),
            url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?hhmapk') format('woff'),
            url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?hhmapk##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="sld-icon-"], [class*=" sld-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sld-icon-color {
    &:before {
      content: $sld-icon-color; 
    }
  }
  .sld-icon-eyes {
    &:before {
      content: $sld-icon-eyes; 
    }
  }
  .sld-icon-arrow-bottom {
    &:before {
      content: $sld-icon-arrow-bottom; 
    }
  }
  .sld-icon-arrow-left {
    &:before {
      content: $sld-icon-arrow-left; 
    }
  }
  .sld-icon-arrow-right {
    &:before {
      content: $sld-icon-arrow-right; 
    }
  }
  .sld-icon-search {
    &:before {
      content: $sld-icon-search; 
    }
  }
  .sld-icon-download {
    &:before {
      content: $sld-icon-download; 
    }
  }
  .sld-icon-favicon {
    &:before {
      content: $sld-icon-favicon; 
    }
  }
  .sld-icon-file-picture {
    &:before {
      content: $sld-icon-file-picture; 
    }
  }
  .sld-icon-marketing-catalogue {
    &:before {
      content: $sld-icon-marketing-catalogue; 
    }
  }
  .sld-icon-marketing-plv {
    &:before {
      content: $sld-icon-marketing-plv; 
    }
  }
  .sld-icon-marketing-poster {
    &:before {
      content: $sld-icon-marketing-poster; 
    }
  }
  .sld-icon-marketing-programmeCom {
    &:before {
      content: $sld-icon-marketing-programmeCom; 
    }
  }
  .sld-icon-marketing-rs {
    &:before {
      content: $sld-icon-marketing-rs; 
    }
  }
  .sld-icon-marketing-video {
    &:before {
      content: $sld-icon-marketing-video; 
    }
  }
  .sld-icon-menu {
    &:before {
      content: $sld-icon-menu; 
    }
  }
  .sld-icon-user {
    &:before {
      content: $sld-icon-user; 
    }
  }
  .sld-icon-mobile-logo {
    &:before {
      content: $sld-icon-mobile-logo; 
    }
  }
  .sld-icon-cart {
    &:before {
      content: $sld-icon-cart; 
    }
  }
  .sld-icon-more {
    &:before {
      content: $sld-icon-more; 
    }
  }
  .sld-icon-certificat {
    &:before {
      content: $sld-icon-certificat; 
    }
  }
  .sld-icon-message {
    &:before {
      content: $sld-icon-message; 
    }
  }
  .sld-icon-prices {
    &:before {
      content: $sld-icon-prices; 
    }
  }
  .sld-icon-delivery {
    &:before {
      content: $sld-icon-delivery; 
    }
  }
  .sld-icon-secure {
    &:before {
      content: $sld-icon-secure; 
    }
  }
  .sld-icon-size {
    &:before {
      content: $sld-icon-size; 
    }
  }
  .sld-icon-cookie {
    &:before {
      content: $sld-icon-cookie; 
    }
  }
  .sld-icon-facebook {
    &:before {
      content: $sld-icon-facebook; 
    }
  }
  .sld-icon-instagram {
    &:before {
      content: $sld-icon-instagram; 
    }
  }
  .sld-icon-linkedin {
    &:before {
      content: $sld-icon-linkedin; 
    }
  }
  .sld-icon-pinterest {
    &:before {
      content: $sld-icon-pinterest; 
    }
  }
  .sld-icon-rss {
    &:before {
      content: $sld-icon-rss; 
    }
  }
  .sld-icon-twitter {
    &:before {
      content: $sld-icon-twitter; 
    }
  }
  .sld-icon-vimeo {
    &:before {
      content: $sld-icon-vimeo; 
    }
  }
  .sld-icon-youtube {
    &:before {
      content: $sld-icon-youtube; 
    }
  }
  
  