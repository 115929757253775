@import "../partials/variables";

#footer {
    .btn-light {
        padding: 14px 30px;
        color: $color_secondary;
        font-weight: 700;
        &:hover {
            background-color: $color_secondary;
            color: #fff;
        }
    }
    .footer-bottom {
        position: relative;
        border-top: 1px solid #363636;
        &:before,
        &:after {
            position: absolute;
            z-index: 10;
            left: 50%;
            transform: translateX(-50%);
        }
        &:before {
            content: '';
            display: block;
            width: 115px;
            height: 20px;
            background-color: #000;
            top: -10px;
        }
        &:after {
            content: '\e910';
            font-family: $font_icon;
            font-size: 50px;
            line-height: 50px;
            top: -25px;
        }
        .typo {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
        p {
            margin-bottom: 0;
        }
    }
    .footer-bottom-links {
        ul {
            margin-bottom: 0;
        }
        li {
            text-decoration: none;
            list-style: none;
            padding-top: 0;
        }
        a {
            margin-left: 10px;
            &:hover {
                color: $color_secondary;
            }
        }
    }
}