@import "../partials/variables";

.product-miniature {
  .variant-links-list {
    display: block;
    width: 100%;
    height: 26px;
    overflow: hidden;
    span.variant {
      position: relative;
      float: left;
      margin: 3px;
      width: 20px;
      height: 20px;
      background-size: 20px 20px;
      border-radius: 50%;
      border: 2px solid transparent;
      cursor: pointer;
      .check-circle{
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px $color_primary solid;
      }
      &.border-grey{
        border-color: $color-text;
      }
      &.selected{
        border-radius: 50%;
        .check-circle{
          display: block;
        }
        &.border-grey{
          border-color: transparent;
        }
      }
    }
    .more{
      float: left;
      margin: 3px;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      background-color: $color_primary;
      color: $color_white;
      border: 1px solid $color_primary;
      &:hover{
        background-color: $color_white;
        color: $color_primary;
      }
    }
  }
}

.sld-link-arrow,
.sld-js-anchor {
  display: inline-flex;
  align-items: center;
  position: relative;
  font-weight: 700;
  .sld-icon-arrow-right {
    font-size: 10px;
    margin-left: 6px;
  }
  &:after {
    content: '';
    display: block;
    width: calc(100% - 16px);
    height: 1px;
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    background-color: $color_secondary;
  }
  &:hover,
  &:active {
    &:after {
      background-color: #000;
    }
  }
}
.sld-variant-title {
  margin-bottom: 10px;
  span {
    font-weight: 700;
  }
}
.sld-inline-price-add-to-cart {
  display: flex;
  flex-wrap: wrap;
  .product-prices {
    min-width: 22%;
    margin-right: 20px;
  }
  .product-add-to-cart  {
    flex: 1;
  }
}
.label-price,
.label-quantity {
  font-size: rem(15);
  color: $color_primary;
  margin-bottom: 6px;
}
.sld-product-variants {
  display: flex;
  background-color: #fafafa;
  margin: 0 -60px;
  padding: 20px 60px;
  &> div {
    flex: 1;
  }
}
.sld-variant-radio {
  display: flex;
  align-items: center;
  padding: 2px 10px;
}
.sld-variant-item-color {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  input {
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    &:not(:checked) {
      &+ .sld-variant-item-radio:after {
        border-color: #bdbdbd !important;
      }
    }
    &:checked {
      &+ .sld-variant-item-radio:after {
        border-color: $color_secondary !important;
      }
    }
  }
  .sld-variant-item-radio {
    display: block;
    width: 16px;
    height: 16px;
    border: 2px solid transparent;
    border-radius: 16px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      transform: translate(-6px, -6px);
      width: 24px;
      height: 24px;
      border-radius: 24px;
      border-width: 2px;
      border-style: solid;
      border-color: inherit;
    }
  }
}
.sld-variant-select {
  select {
    border-width: 0;
    background-color: transparent;
    font-weight: 700;
  }
}
.sld-stock {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-weight: 500;
  i {
    font-size: 30px;
  }
  span {
    font-size: 15px;
  }
}
.sld-produc-pictures-download {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  background-color: hexopacity(#000000D4);
  color: #fff;
  padding: 10px 20px;
}
#product-pictures-download {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  i {
    font-size: 20px;
    margin-right: 6px;
  }
}
.product-marketing {
  margin-top: 30px;
  margin-bottom: 30px;
}
.product-marketing-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.product-marketing-list {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -10px;
}
.product-marketing-item {
  display: flex;
  padding: 20px;
  border: 1px solid #707070;
  width: 250px;
  margin: 10px;
}
.product-marketing-item-icon {
  margin-right: 20px;
}
.product-marketing-item-title {
  font-family: $font_primary;
  color: #000;
  font-size: rem(18);
  font-weight: 700;
}
.product-extra-block.sld-video {
  .extra-content {
    display: flex;
    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
  }
  .content-text {
    padding: 20px 30px;
    @include media-breakpoint-down(lg) {
      padding: 20px 0;
    }
  }
  .btn-default {
    margin-top: 20px;
    font-weight: 700;
    @include my-button-variant(
      $btn-primary-color,
      $btn-primary-bg,
      $btn-primary-border,
      $btn-primary-color-hover,
      $btn-primary-bg-hover,
      $btn-primary-border-hover
    );
  }
}
.sld-js-scrollbox-prev,
.sld-js-scrollbox-next {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  top: calc(50% - 25px);
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  .touch-screen & {
    display: none;
  }
  i {
    font-size: 20px;
    color: $color_secondary;
    opacity: .5;
  }
  &:hover {
    i {
      opacity: 1;
    }
  }
}
.sld-js-scrollbox-prev {
  left: 10px;
}
.sld-js-scrollbox-next {
  right: 10px;
}