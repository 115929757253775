@import "../partials/variables";

.elementor {
  // buttons
  .elementor-element {
    &.elementor-button-primary {
      .elementor-button {
        border-width: 1px;
        border-style: solid;
        border-radius: 40px;
        padding: 14px 30px;
        font-weight: 600;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
        @include my-button-variant(
                        $btn-primary-color,
                        $btn-primary-bg,
                        $btn-primary-border,
                        $btn-primary-color-hover,
                        $btn-primary-bg-hover,
                        $btn-primary-border-hover
        );
      }
    }
    &.elementor-button-outline-primary {
      .elementor-button {
        border-width: 1px;
        border-style: solid;
        border-radius: 40px;
        padding: 14px 30px;
        font-weight: 600;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
        &:not(#e) {
          color: $color_secondary;
        }
        @include my-button-variant(
                        $color_secondary,
                        #fff,
                        $color_secondary,
                        #fff,
                        $color_secondary,
                        $color_secondary
        );
      }
    }
    &.elementor-button-secondary {
      .elementor-button {
        font-weight: 600;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
        @include my-button-variant(
                        $btn-secondary-color,
                        $btn-secondary-bg,
                        $btn-secondary-border,
                        $btn-secondary-color-hover,
                        $btn-secondary-bg-hover,
                        $btn-secondary-border-hover
        );
      }
    }
  }
  // END - buttons

  // grid products
  .elementor-product-grid {
    grid-column-gap: 0;
  }
  // grid products

  .elementor-slick-slider {
    .slick-prev,
    .slick-next {
      top: -50px;
    }

    .slick-prev {
      right: 60px;
      left: initial !important;
    }
  }
}
