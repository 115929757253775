//---------- THEME COLOR
$color_text:        #5d5d5d; // den
$color_text_bold:   #3d3d3d; // den dam
$color_text_light:  #6f6f6f; // den nhat
$color_green:       #5a9e74; // xanh la
$color_gray:        #f7f7f7; // xam
$color_border:      #c7c7c7; // border
$color_box_bg:      #ffffff; // mau box
$color_pink:        #fd6282; // hong
$color_blue:        #007ad1; // xanh
$color_white:       #ffffff; // trang
$color_orange:      #f88e4f; // cam

$color_primary: #180400;
$color_secondary: #EA5351;
$color_tertiary: #7DA876;
$color_quaternary: #EA5D0B;
$color_quinary: #980000;
$color_shadow: rgba(0,0,0,.3);
$color_header_icon: #000000;

$path_img: '../img/';
$path_fonts: '../fonts/';

$font_primary: 'montserrat';
$font_secondary: 'josefin-sans';

$font_material: 'Material Icons';
$font_awesome: 'FontAwesome';
$font_icon: 'sld-font-icon';

$transition: 300ms;
$shadow: 0 3px 6px $color_shadow;

$header_icon_padding: 7px;
$header_icon_size: 1.6rem;

// sld font icon
$icomoon-font-family: $font_icon !default;
$icomoon-font-path: "#{$path_fonts}#{$icomoon-font-family}" !default;

$sld_font_icon: $icomoon-font-family;

$sld-icon-color: "\e90f";
$sld-icon-eyes: "\e90c";
$sld-icon-arrow-bottom: "\e900";
$sld-icon-arrow-left: "\e901";
$sld-icon-arrow-right: "\e902";
$sld-icon-search: "\e90d";
$sld-icon-download: "\e90e";
$sld-icon-favicon: "\e910";
$sld-icon-file-picture: "\e911";
$sld-icon-marketing-catalogue: "\e912";
$sld-icon-marketing-plv: "\e913";
$sld-icon-marketing-poster: "\e914";
$sld-icon-marketing-programmeCom: "\e915";
$sld-icon-marketing-rs: "\e916";
$sld-icon-marketing-video: "\e917";
$sld-icon-menu: "\e918";
$sld-icon-user: "\e919";
$sld-icon-mobile-logo: "\e91a";
$sld-icon-cart: "\e91b";
$sld-icon-more: "\e91c";
$sld-icon-certificat: "\e91d";
$sld-icon-message: "\e91e";
$sld-icon-prices: "\e91f";
$sld-icon-delivery: "\e920";
$sld-icon-secure: "\e921";
$sld-icon-size: "\e922";
$sld-icon-cookie: "\e908";
$sld-icon-facebook: "\e903";
$sld-icon-instagram: "\e904";
$sld-icon-linkedin: "\e905";
$sld-icon-pinterest: "\e906";
$sld-icon-rss: "\e907";
$sld-icon-twitter: "\e909";
$sld-icon-vimeo: "\e90a";
$sld-icon-youtube: "\e90b";

//---------- BOOTSTRAP
$grid-columns:      12;
$grid-gutter-width: 20px;
//$grid-gutter-width-small: 20px;
$paragraph-margin-bottom: 0.625rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1260px,
  xxl: 1500px,
  xxxl: 1680px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1620px
);

$container-xs-max-width: 480px;

//---------- FONTS
$text-font:                         $font_primary, sans-serif;
$text-color:                        $color_text;

$title-font:                        $font_secondary, sans-serif;
$title-color:                       $color_text_bold;

$border-color:                      $color_border;
$border-color-lighten:              lighten($border-color, 15%);
$dark-border-color:                 #3b3b3b;
$light-border-color:                #ffffff;

$box-shadow-color:                  rgba(0, 0, 0, 0.2);

$link-color:                        $color_secondary;
$link-hover-color:                  $color_primary;

$box-background:                    $color_box_bg;
$box-background-darken:             darken($box-background, 8%);
$light-background:                  $color_white;
$box-padding:                       0.9375rem;

$light-text-color:                  $color_white;

$wrapper-background:                #fff;

$center-enable-background:          false;
$center-background:                 #ffffff;
$center-border:                     none;
$center-padding:                    10px;

$column-block-background:           $color_box_bg;

$icon-color:                        $color_secondary;

$header-background:                 #ffffff;
$breadcrumb-background:             #ffffff;

$footer-background:                 #000000;
$footer-text-color:                 #ffffff;
$footer-title-color:                #ffffff;


@function parseInt($n) {
  @return $n / ($n * 0 + 1);
}

@function rem($pxValue) {
  $remValue: ($pxValue / 16) + rem;
  @return $remValue;
}

$opacityTable: (FF: 1, 
FC: .99, FA: .98, F7: .97, F5: .96, F2: .95, F0: .94, ED: .93, EB: .92, E8: .91, E6: .90, E3: .89, E0: .88, DE: .87, DB: .86, D9: .85, D6: .84, D4: .83, D1: .82, CF: .81, CC: .80,
C9: .79, C7: .78, C4: .77, C2: .76, BF: .75, BD: .74, BA: .73, B8: .72, B5: .71, B3: .70, B0: .69, AD: .68, AB: .67, A8: .66, A6: .65, A3: .64, A1: .63, 9E: .62, 9C: .61, 99: .60,
96: .59, 94: .58, 91: .57, 8F: .56, 8C: .55, 8A: .54, 87: .53, 85: .52, 82: .51, 80: .50, 7D: .49, 7A: .48, 78: .47, 75: .46, 73: .45, 70: .44, 6E: .43, 6B: .42, 69: .41, 66: .40,
63: .39, 61: .38, 5E: .37, 5C: .36, 59: .35, 57: .34, 54: .33, 52: .32, 4F: .31, 4D: .30, 4A: .29, 47: .28, 45: .27, 42: .26, 40: .25, 3D: .24, 3B: .23, 38: .22, 36: .21, 33: .20,
30: .19, 2E: .18, 2B: .17, 29: .16, 26: .15, 24: .14, 21: .13, 1F: .12, 1C: .11, 1A: .10, 17: .9, 14: .8, 12: .7, 0F: .6, 0D: .5, 0A: .4, 08: .3, 05: .2, 03: .1, 00: 0);

@function hexopacity($hexadecimal) {
  $opacity: opacity($hexadecimal);
  $red: red($hexadecimal);
  $green: green($hexadecimal);
  $blue: blue($hexadecimal);
  @return rgba($red, $green, $blue, $opacity);
}

//---------- Bootstrap
$font-size-root:    16px;
$font-size-base:    1rem;
$line-height-base:  1.25;

$input-bg:            $color_white;
$input-color:         $text-color;
$input-border-color:  $border-color;
$input-color-placeholder: $color_text_light;
$input-focus-border-color: $border-color;
$input-btn-focus-box-shadow: none;

$btn-border-radius:       0;
$btn-line-height:         1.25rem;
$input-btn-border-width:  1px;
$input-padding-x:         0.75rem;
$input-padding-y:         0.375rem;
$input-line-height:       $line-height-base;
$input-height:            (($font-size-base * $line-height-base) + ($input-padding-y * 2));
$input-border-radius:     0;

$table-striped-order:   even; // even || odd
$table-color:           $color_text;
$table-bg:              #fff;
$table-accent-bg:       #F2F2F2;
$table-hover-color:     $color_text;
$table-hover-bg:        #c8c8c8;
$table-active-bg:       #f7f7f7;
$table-border-width:    1px;
$table-border-color:    #fff;


$tooltip-color:         #fff;
$tooltip-bg:            #000;
$tooltip-padding-y:     0;
$tooltip-padding-x:     5px;
$tooltip-margin:        2px;
$tooltip-arrow-color:   $tooltip-bg;

$pre-color:               $text-color;
$blockquote-small-color:  $color_text_light;
$thumbnail-border-color:  $border-color;
$input-btn-border-width:  1px;

$primary:       $color_primary;
$secondary:     $color_secondary;

// not bootstrap
$btn-border-width:        1px;
$input-max-width:         220px;
$base-height:             (($line-height-base + (parseInt($input-padding-y) * 2)) * $font-size-root) + 2;
$base-padding:            parseInt($input-padding-y) * $font-size-root + 1;
$base-padding-border1px:  parseInt($input-padding-y) * $font-size-root;
$base-padding-border2px:  parseInt($input-padding-y) * $font-size-root - 1;

$custom-select-bg:        $input-bg;

$btn-primary-color:         $color_white;
$btn-primary-bg:            $color_secondary;
$btn-primary-border:        $color_secondary;
$btn-primary-color-hover:   $color_white;
$btn-primary-bg-hover:      #D14B4A;
$btn-primary-border-hover:  #D14B4A;

$btn-secondary-color:       $color_primary;
$btn-secondary-bg:          $color_white;
$btn-secondary-border:      $color_white;
$btn-secondary-color-hover: $color_white;
$btn-secondary-bg-hover:    $color_primary;
$btn-secondary-border-hover:$color_primary;

$btn-cart-color:            $color_white;
$btn-cart-bg:               $color_secondary;
$btn-cart-border:           $color_secondary;
$btn-cart-color-hover:      $color_white;
$btn-cart-bg-hover:         #D14B4A;
$btn-cart-border-hover:     #D14B4A;

//---------- CLASSIC
$h1-font-size: rem(45);
$h2-font-size: rem(30);
$h3-font-size: rem(20);
$h4-font-size: rem(16);
$h5-font-size: rem(14);
$h6-font-size: rem(14);

$headings-font-weight: 400;
$border-radius: 0;
$extra-small-space: 10px;
$small-space: 20px;
$medium-space: 30px;
$large-space: 40px;
$extra-large-space: 50px;
$btn-tertiary-color: #6C868E;
$btn-tertiary-hover: #BBCDD2;
$base-font-size: 1rem;
$font-size-lg: 0.9375rem;
$font-size-sm: 0.875rem;
$font-size-xs: 0.8125rem;

$display1-size: 3.125rem;
$display1-weight: 600;
$display2-size: 2.1875rem;
$display2-weight: 400;
$display3-size: 1.5625rem;
$display3-weight: 400;
$display4-size: 1.25rem;
$display4-weight: 400;
