@import "../partials/variables";

$search_widget_height: 40px;
$search_widget_button_size: rem(18);
$search_widget_button_marge: 10px;
$search_widget_button_color: $color_text;
$search_widget_button_color_hover: $color_secondary;
$search_widget_button_bg_hover: transparent;
$search_widget_button_position: right; // left | right
$search_widget_style_desktop: full; // bar | light | full
$search_widget_Style_mobile: full; // bar | light | full
$search_widget_input_bg: #F3EFEC;
$search_widget_input_bg_focus: #F3EFEC;
$search_widget_input_color: $color_primary;
$search_widget_input_color_focus: $color_primary;
$search_widget_input_width: 600px;

.search-widget {
}
// button to display search bar
.sld-js-search-open {
  border: none;
  background-color: transparent;
  font-size: $search_widget_button_size;
  opacity: 1;
  transition: $transition opacity;
}
// button to display search bar on sidebar menu
.sld-js-menu-left-search {
  display: flex;
  align-items: center;
  border-width: 1px 0;
  border-style: solid;
  border-color: #c7c7c7;
  background: transparent;
  min-height: 60px;
  width: 100%;
  padding: 6px 10px;
  .sld-icon-search {
    font-size: $search_widget_button_size;
    font-weight: 700;
    margin-right: 6px;
  }
}
.search-widget-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-widget-form {
  position: relative;
  width: 100%;
  max-width: $search_widget_input_width;
  &.active {
    .search-widget-field {
      opacity: 1;
      visibility: visible;
      @if $search_widget_button_position == right {
        right: 100%;
      }
      @else {
        left: 100%;
      }
    }
  }
}
.search-widget-field {
  min-height: $search_widget_height;
  background-color: $search_widget_input_bg;
  color: $search_widget_input_color;
  border:  none;
  outline: 0;
  .desktop-header-version & {
    @if $search_widget_style_desktop == light {
      position: absolute;
      z-index: 100;
      top: 0;
      width: $search_widget_input_width;
      opacity: 0;
      visibility: hidden;
      @if $search_widget_button_position == right {
        right: 10px;
        transition: $transition opacity, $transition right;
      }
      @else {
        left: 10px;
        transition: $transition opacity, $transition left;
      }
    }
    @else {
      width: 100%;
    }
  }
  .mobile-header-version & {
    @if $search_widget_Style_mobile == light {
      position: absolute;
      z-index: 100;
      top: 0;
      width: 280px;
      max-width: calc(100vw - 100px);
      opacity: 0;
      visibility: hidden;
      @if $search_widget_button_position == right {
        right: 10px;
        transition: $transition opacity, $transition right;
      }
      @else {
        left: 10px;
        transition: $transition opacity, $transition left;
      }
    }
    @else {
      width: 100%;
    }
  }
  &::placeholder {
    opacity: 1;
    color: $search_widget_input_color;
  }
  &:focus {
    color: $search_widget_input_bg_focus;
    background-color: $search_widget_input_color_focus;
    &::placeholder {
      color: $search_widget_input_bg_focus;
    }
    // &+ .search-widget-button {
    //   color: $search_widget_input_bg_focus;
    // }
  }
}
button.search-widget-button {
  .desktop-header-version & {
    @if $search_widget_style_desktop != light {
      position: absolute;
      z-index: 10;
      top: 0;
    }
    font-size: $search_widget_button_size;
  }
  .mobile-header-version & {
    @if $search_widget_Style_mobile != light {
      position: absolute;
      z-index: 10;
      top: 0;
    }
    font-size: rem(26);
  }
  border: none;
  background-color: transparent;
  min-height: $search_widget_height;
  color: $search_widget_button_color;
  padding-left: 10px;
  padding-right: 10px;
  transition: $transition color, $transition background-color;
  &:hover,
  &:focus {
    background-color: $search_widget_button_bg_hover;
    color: $search_widget_button_color_hover;
  }
}
@if $search_widget_button_position == right {
  .search-widget-field {
    padding: 6px ($search_widget_height + 10px + $search_widget_button_marge) 6px 10px;
  }
  .search-widget-button {
    right: $search_widget_button_marge;
  }
}
@else {
  .search-widget-field {
    padding: 6px 10px 6px ($search_widget_height + 10px + $search_widget_button_marge);
  }
  .search-widget-button {
    left: $search_widget_button_marge;
  }
}

// navbar search top
.st-effect-top {
  .st-sidebar-top-main {
    padding: 50px 10px;
  }
  .sld-js-search-open {
    opacity: 0;
  }
  @include media-breakpoint-down(sm) {
    .search-widget-form {
      margin-right: 50px;
    }
    .st-sidebar-top-main {
      padding: 10px;
    }
  }
}
// END - navbar search top

// navbar menu
.st-effect-left {
  .sld-js-search-open {
    opacity: 0;
  }
  &.st-menu {
    width: 100%;
    .searchbar-wrapper {
      width: 100%;
      min-height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #70707021;
      border-bottom: 1px solid #70707021;
      #search_widget {
        width: calc(100% - 4px);
      }
      .search-widget-content{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
      }
      .search-widget-field {
        flex: 1;
        width: initial;
        padding: 0;
        border: none;
      }
      .sld-icon-search {
        font-size: 1rem;
      }
    }
  }
}
