#authentication {
    #wrapper {
        background-image: url('../../assets/img/connexion.jpg');
        background-size: cover;
    }
    .main-content {
        height: 100%;
    }
    .container {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    .page-heading {
        max-width: 450px;
        margin: 0 auto;   
        background-color: rgba(#fff, .95);
        border-radius: 17px 17px 0 0;
        padding: 30px;
        margin-bottom: 10px;
    }
    .sld-connection-information {
        text-align: left;
        font-weight: 500;
        color: #000;
    }
    .login-form {
        padding: 60px;
        background-color: rgba(#fff, .95);
        border-radius: 0 0 17px 17px;
        max-width: 450px;
        margin: 0 auto;
        @include media-breakpoint-down(sm) {
            padding: 20px;
        }
    }
    .form-group {
        &.email,
        &.password {
            flex-direction: column;
        }
        &.email .form-control-label:before {
            content: '\e919';
        }
        &.password .form-control-label:before {
            content: '\e921';
        }
        .form-control-label  {
            text-align: left;
            opacity: .7;
            font-weight: 400;
            font-size: rem(14);
            &:before {
                font-family: $font_icon;
                font-size: 20px;
            }
        }
        .col-12 {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .login-form-content {
        position: relative;
        .password-forgot {
            position: absolute;
            z-index: 10;
            bottom: 48px;
            right: 0;
            text-decoration: underline;
            font-size: rem(14);
            color: $color_secondary;
        }
    }
    .sld-create-account {
        margin-top: 40px;
        p {
            font-weight: 500;
            color: #000;
            margin-bottom: 0;
        }
        a {
            font-weight: 500;
            color: $color_secondary;
            text-decoration: underline;
        }
    }
    .footer-authentication {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        a {
            color: #fff;
        }
    }
}