@import "../partials/variables";

$color_menu: #000;
$color_menu_hover: #000;
$color_menu_bg: transparent;
$color_menu_bg_hover: #fff;
$color_submenu: #929292;
$color_submenu_hover: $color_primary;
$color_submenu_bg: #fff;
$color_submenu_bg_hover: $color_primary;
$color_menu_mobile_bg: #fff;
$color_menu_mobile: #000;
$color_menu_burger: #000;
$color_menu_burger_bg: inherit;

$menu_breakpoint-up: 'xl';
$menu_breakpoint-down: 'lg';

// test
// .anav-top .amenu-item:first-child .adropdown {
//   opacity: 1 !important;
//   visibility: visible !important;
// }
// desktop
.header-display-navfullwidth  {
  position: relative;
  #amegamenu {
    // menu top
    .anav-top {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      min-height: 50px;
    }
    .amenu-icon {
      max-height: 24px;
      width: auto;
    }
    .amenu-item {
      display: flex;
      align-items: center;
      position: relative;
      color: $color_menu;
      background-color: $color_menu_bg;

      &:hover,
      &:focus {
        .amenu-item-text {
          color: $color_menu_hover;
          background-color: $color_menu_bg_hover;
        }
      }
      &:hover,
      &.hover,
      &:focus {
        background: $color_menu_bg_hover;
        .amenu-link {
          > span:after {
            left: 0;
            right: 0;
          }
          &:after {
            background-color: $color_primary;
          }
        }
        &.has-submenu {
          .adropdown {
            visibility: visible;
            opacity: 1;
            top: 100%;
            transition: all 0.3s;
          }
        }
      }
      > .sld-js-menu-submenu-button {
        display: none;
      }
    }
    .amenu-item-text {
      position: relative;
      overflow: hidden;
      padding: 10px;
      font-size: rem(16);
      font-weight: 300;
      text-transform: uppercase;
    }
    .amenu-link {
      display: flex;
      align-items: center;
      height: 100%;
      min-height: 40px;
      cursor: pointer;
      white-space: nowrap;
      > sup {
        line-height: 13px;
        padding: 1px 3px;
        font-weight: normal;
        text-transform: uppercase;
        text-align: center;
        background-color: $color_pink;
        color: $light-text-color;
        font-size: 10px;
        display: inline-block;
        border-radius: 1px;
        transition: all 0.2s;
        white-space: nowrap;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        left: 10px;
        bottom: 0;
        width: calc(100% - 20px);
        height: 4px;
        background-color: transparent;
      }
    }
    // dropdown column
    .dropdown-content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 40px 0;
      &.acot1 {
        &> .sld-menu-item,
        .sld-menu-dynamic,
        .sld-menu-product,
        .sld-menu-manufacturer {
          width: 100%;
        }
      }
      &.acot2 {
        &> .sld-menu-item,
        .sld-menu-dynamic,
        .sld-menu-product,
        .sld-menu-manufacturer{
          width: 50%;
        }
      }
      &.acot3 {
        &> .sld-menu-item,
        .sld-menu-dynamic,
        .sld-menu-product,
        .sld-menu-manufacturer{
          width: 33.33%;
        }
      }
      &.acot4 {
        &> .sld-menu-item,
        .sld-menu-dynamic,
        .sld-menu-product,
        .sld-menu-manufacturer{
          width: 25%;
        }
      }
      &.acot5 {
        &> .sld-menu-item,
        .sld-menu-dynamic,
        .sld-menu-product,
        .sld-menu-manufacturer{
          width: 20%;
        }
      }
      .sld-menu-col {
        display: flex;
        flex-wrap: wrap;
        position: relative;
      }
      .sld-menu-dynamic {
        ul {
          display: none;
        }
      }
    }
    // category
    .sld-menu-container-items {
      min-height: 100%;
      border-left: 1px solid $color_border;
    }
    .sld-menu-custom-html .sld-menu-item,
    .sld-menu-subcategory-link {
      padding-left: 10px;
      font-size: 1rem;
      &:hover,
      &:focus {
        color: $color_submenu_hover;
        text-decoration: underline;
      }
    }
    .sld-menu-item .sld-menu-category-link  + .sld-js-menu-submenu-button {
      display: none;
    }
    .sld-category-subs {
      padding-bottom: 10px;
      &:hover {
        .sld-menu-item {
          opacity: .4;
        }
      }
      .sld-menu-item {
        &:hover {
          opacity: 1;
        }
      }
    }
    .sld-menu-dynamic-item {
      &:hover {
        background-color: $color_submenu_bg_hover;
        > .sld-menu-category-link,
        > .sld-js-menu-submenu-button {
          color:$color_submenu_hover;
        }
        > ul {
          display: block !important;
        }
      }
    }
    .sld-menu-back-link-mobile {
      display: none;
    }
  }
}

// sub dropdown
.header-display-navfullwidth {
  .nav-tabs {
    margin-bottom: 0;
    justify-content: initial;
  }
  .sld-nav-sub-dropdown {
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
    min-width: 400px;
    padding: 30px 0 30px 80px;
    .sld-menu-category-link {
      display: flex;
      font-weight: 600;
      width: initial;
      text-transform: uppercase;
      transition: $transition color;
      margin: 10px;
      &:before {
        content: '';
        display: block;
        width: 0;
        height: 100%;
        margin-right: 0;
        background-color: $color_primary;
        transition: $transition width, $transition margin-right;
      }
      &:after {
        content: '\f105';
        font-family: 'FontAwesome';
        display: block;
        font-size: 18px;
        font-weight: 400;
        color: inherit;
        margin-left: auto;
      }
      &.active {
        color: $color_primary;
        &::before {
          width: 2px;
          margin-right: 10px;
        }
      }
    }
  }
  .sub-dropdown-column {
    @include media-breakpoint-up(xl) {
      padding: 40px;
    }
  }
  .sld-sub-dropdown {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
  @include media-breakpoint-up($menu_breakpoint-up) {
    .sub-dropdown-content {
      display: none;
      &.active {
        display: block;
      }
      .sld-js-sub-dropdown-link {
        display: none;
      }
    }
  }
    // columns dropdown
  .adrd1 {
    .acot1 {
      width: 100%;
    }
  }
  .adrd2 {
    .acot1 {
      width: 50%;
    }
    .acot2 {
      width: 100%;
    }
  }
  .adrd3 {
    .acot1 {
      width: 33.333%;
    }
    .acot2 {
      width: 66.666%;
    }
    .acot3 {
      width: 100%;
    }
  }
  .adrd4 {
    .acot1 {
      width: 25%;
    }
    .acot2 {
      width: 50%;
    }
    .acot3 {
      width: 75%;
    }
    .acot4 {
      width: 100%;
    }
  }
  .adrd5 {
    .acot1 {
      width: 20%;
    }
    .acot2 {
      width: 40%;
    }
    .acot3 {
      width: 60%;
    }
    .acot4 {
      width: 80%;
    }
    .acot5 {
      width: 100%;
    }
  }
  // columns sub dropdown
  .acot1 {
    .sdc1 {
      width: 100%;
    }
  }
  .acot2 {
    .sdc1 {
      width: 50%;
    }
    .sdc2 {
      width: 100%;
    }
  }
  .acot3 {
    .sdc1 {
      width: 33.333%;
    }
    .sdc2 {
      width: 66.666%;
    }
    .sdc3 {
      width: 100%;
    }
  }
  .acot4 {
    .sdc1 {
      width: 25%;
    }
    .sdc2 {
      width: 50%;
    }
    .sdc3 {
      width: 75%;
    }
    .sdc4 {
      width: 100%;
    }
  }
  .acot5 {
    .sdc1 {
      width: 20%;
    }
    .sdc2 {
      width: 40%;
    }
    .sdc3 {
      width: 60%;
    }
    .sdc4 {
      width: 80%;
    }
    .sdc5 {
      width: 100%;
    }
  }
}

// mobile / tablet
.sidebar-menu {
  padding: 0;
  .amenu-link + .sld-js-menu-submenu-button {
    transform: rotate(-90deg);
  }
}
.sidebar-menu,
#mobile-amegamenu {
  flex: 1;
  // menu top
  .anav-top {
    padding: 10px;
    background-color: $color_menu_mobile_bg;
  }
  .amenu-icon {
    max-height: 20px;
    width: auto;
  }
  .amenu-item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &> .amenu-link {
      display: flex;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 20px;
      flex: 1;
      &:before {
        display: block;
        margin-right: 10px;
      }
    }
  }
  .amenu-item,
  .sld-menu-item,
  .sub-dropdown-content,
  .sld-menu-dynamic-item {
    &:not(:last-child ) {
      border-bottom: 1px solid #F7F7F7;
    }
  }
  .amenu-link,
  .sld-menu-item a,
  .sld-menu-dynamic-item a {
    color: $color_menu_mobile;
    padding: 10px 2px;
    font-size: 1rem;
  }
  // dropdown
  .adropdown {
    margin-left: 0 !important;
    position: relative;
    transition: $transition height;
    height: 100%;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .dropdown-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .sld-menu-container-items {
    bottom: 0;
    padding: 10px;
    background-color: $color_menu_mobile_bg;
    &.active {
      left: 0;
    }
  }
  .sld-menu-item,
  .sld-menu-dynamic {
    width: 100%;
  }
  // link back
  .sld-menu-back-link-mobile {
    display: block;
    width: 100%;
    min-height: 35px;
    margin-bottom: 10px;
  }
  .sld-js-menu-submenu-button {
    color: $color_primary;
  }
  .amenu-item,
  .sld-menu-item,
  .sld-menu-dynamic-item {
    background-color: $color_menu_bg_hover;
  }
  .sld-menu-subcategory-link {
    padding: 10px;
    font-size: 1rem;
  }
  .sld-menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sld-menu-category-image {
    margin-right: auto;
    margin-left: auto;
  }
  .adropdown,
  .sld-sub-dropdown,
  .sld-category-subs {
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 100%;
    width: 100%;
    padding: 10px;
    transition: $transition left;
    background-color: $color_menu_mobile_bg;
    &.active {
      left: 0;
    }
  }
  .sld-menu-custom-html {
    background-color: $color_menu_mobile_bg ;
  }
  // product
  .sld-menu-product {
    width: 50%;
  }
  // manufacturer
  .brand-logo {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    a {
      display: block;
    }
  }
}
// responsive
body:not(.touch-screen):not(.sld-menu-responsive)  {
  .left-nav-trigger {
    display: none;
  }
}
// END - responsive

// category
.sld-js-menu-submenu-button {
  min-height: 35px;
  min-width: 35px;
  font-size: 1.2rem;
  border: none;
  background-color: transparent;
}
.sld-js-menu-back-button {
  min-height: 35px;
  font-size: 1.2rem;
  border: none;
  background-color: transparent;
  color: $color_menu_mobile;
  padding: 0;
  .material-icons {
    font-size: rem(30);
  }
}
.sld-menu-container-items {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 100%;
  width: 100%;
  transition: $transition left;
}
.sld-menu-dynamic-item {
  width: 100%;
  min-height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sld-menu-category-link {
  display: block;
  width: 100%;
  color: $color_submenu;
  padding: 7px 10px;
  font-size: 1rem;
  margin-bottom: 10px;
  &:hover {
    text-decoration: underline;
  }
  &> img {
    display: inline-block;
    max-height: 32px;
    width: auto;
    margin-right: 6px;

    &+ .category-title {
      display: inline-block;
      line-height: 32px;
    }
  }
  .category-thumb {
    &+ .category-title {
      text-align: center;
    }
  }
  .category-title {
    display: block;
  }
}
.sld-menu-subcategory-link {
  display: inline-block;
  width: 100%;
}
// END - category

// product
.sld-menu-product {
  padding: 10px 5px;
  .product-name {
    text-align: center;
  }
  .product-price-and-shipping {
    padding-top: 4px;
  }
}
// END - product

// manufacturer
.sld-menu-manufacturer {
  padding-bottom: 10px;
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
  .brand-logo {
    img {
      max-height: 100px;
      width: auto;
    }
  }
  .brand-name {
    font-size: 1rem;
    font-weight: 600;
    margin-top: auto;
  }
}
// END - manufacturer

// static content
.sld-menu-custom-html {
  padding: 10px;
  width: 100%;
}
// END - static content