

.sld-tooltip {
  position: absolute;
  z-index: 100;
  padding: 4px 4px 0;
  border-radius: 2px;
  font-size: rem(14);
  font-weight: 400;
  box-shadow: $shadow;
  background-color: #fff;
  color: #000;
  border-left: 5px solid #DD171B;
  min-width: 250px;
  opacity: 1;
  &.top-left {
    bottom: 100%;
    left: 0;
  }
  &.top-center {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  &.top-right {
    bottom: 100%;
    right: 0;
  }
  &.bottom-left {
    top: 100%;
    left: 0;
  }
  &.bottom-center {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  &.bottom-right {
    top: 100%;
    right: 0;
  }
  &.left-top {
    right: 100%;
    top: 0;
  }
  &.left-center {
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  &.left-bottom {
    right: 100%;
    bottom: 0;
  }
  &.right-top {
    left: 100%;
    top: 0;
  }
  &.right-center {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  &.right-bottom {
    left: 100%;
    bottom: 0;
  }
}