@import "../partials/variables";

.footer-top {
    padding: 40px 10px;
    background-color: #101010;
}
body#index .footer-top {
    background-color: #fff;
    .block-reassurance-icon svg {
        fill: #000;
    }
    .block-reassurance-container-title .h2,
    .block-reassurance-title,
    .block-reassurance-description {
        color: #000000;
    }
    .block-reassurance-container-title .h2 {
        max-width: 310px;
    }
}
$sld-fullright-slick-btn-size: 30px;
.sld-full-right {
    .elementor-slick-slider {
        .slick-prev,
        .slick-next {
            position: absolute;
            background: transparent;
            transition: none;
            width: $sld-fullright-slick-btn-size;
            height: $sld-fullright-slick-btn-size;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                color: #000;
            }
        }
    }

    @media all and (min-width: 1700px) {
        max-width: calc(1620px + (100vw - 1620px) / 2);
        margin-left: auto;
        left: 0 !important;

        .elementor-slick-slider {
            .slick-prev,
            .slick-next {                
                right: calc((100vw - 1600px) /2) !important;
                left: unset !important;
            }

            .slick-prev {
                margin-right: $sld-fullright-slick-btn-size + 10px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .elementor-slick-slider {
            .slick-prev,
            .slick-next {                
                top: -10px !important;
            }
        }
    }
}
@include media-breakpoint-up(xxl) {
    body#index {
        .third-block {
            padding-top: 26px;
        }
    }
}