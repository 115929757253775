@import "../partials/variables";

.social-sharing {
  max-width: 500px;
  margin: 20px 0;
  label {
    margin-right: 20px;
    padding-top: $base-padding;
    float: left;
    display: none;
  }
  > ul {
    justify-content: flex-end;
  }
  li {
    a {
      display: flex;
      align-items: center;
      border-radius: 3px;
      padding: 5px 12px;
      &:hover {
        color: $light-text-color;
        i {
          color: $light-text-color;
        }
      }
      i {
        font-size: 16px;
        padding-right: 8px;
      }
    }
  }
  .facebook {
    a {
      i {
        color: #3b5998;
      }
      &:hover {
        background-color: #3b5998;
      }
    }
  }
  .twitter {
    a {
      i {
        color: #55acee;
      }
      &:hover {
        background-color: #55acee;
      }
    }
  }
  .googleplus {
    a {
      i {
        color: #dd4b39;
      }
      &:hover {
        background-color: #dd4b39;
      }
    }
  }
  .pinterest {
    a {
      i {
        color: #cb2027;
      }
      &:hover {
        background-color: #cb2027;
      }
    }
  }
}